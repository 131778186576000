import React from "react"
import { GatsbySeo } from 'gatsby-plugin-next-seo';

import Layout from "../components/layout"
import ContactCTA from "../components/cta-contact"
import Testimonials from "../components/testimonials"
import HelpList from "../components/help-list"

import Jumbotron from "react-bootstrap/Jumbotron"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Image from "react-bootstrap/Image"
import Button from "react-bootstrap/Button"

import office from "../images/office.jpg"
import officeportrait from "../images/office-portrait.jpg"
import lost from "../images/lost.svg"
import alex from "../images/alex.png"

import faHandsHelping from "../images/fa-hands-helping-duotone.svg"
import faBringFront from "../images/fa-bring-front-solid.svg"
import faDoorOpen from "../images/fa-door-open-solid.svg"
import faBadgeCheck from "../images/fa-badge-check-solid.svg"

export default function Home() {
  return (
    <Layout>
      <GatsbySeo
        title="Home"
        description="YourTherapy is a counselling service that's here for you, to help and support when things could be better or if things couldn't be worse"
        canonical="https://yourtherapydoncaster.co.uk/"
        openGraph={{
         url: "https://yourtherapydoncaster.co.uk",
         title: "YourTherapy",
         description: "YourTherapy is a counselling service that's here for you, to help and support when things could be better or if things couldn't be worse",
         site_name: "YourTherapy",
       }}
      />
      <Jumbotron fluid className="bg-pattern pt-md-3 ">
        <Container>
          <Row xs={1} md={2}>
            <Col>
              <h1>Space to talk</h1>
              <p className="my-4">
                Here for you, to help and support when things could be better or if things couldn't be worse
              </p>
              <Button href="/contact" className="p-2 mb-5 mx-auto bg-white" variant="outline-dark">
                Book an appointment
                <Image height="20" width="20" src={faHandsHelping} className="ml-2 mr-1" alt=""/>
              </Button>
            </Col>
            <Col>
              <Image src={office} fluid className="border rounded shadow-sm border-primary d-none d-md-block" alt="YourTherapy's office in Cusworth, featuring a comfortable-looking brown leather sofa" />
              <Image src={officeportrait} fluid className="border rounded shadow-sm border-primary d-block d-md-none" alt="YourTherapy's office in Cusworth, featuring a comfortable-looking brown leather sofa" />
            </Col>
          </Row>
        </Container>
      </Jumbotron>
      <Container fluid="xl">
        <Row className="justify-content-center my-5">
          <Col xs={{span:12}} sm={{span:10}} lg={{span:8}} xl={{span:3}}>
            <Image height="50" width="50" src={faBringFront} size="2x" className="mx-auto" alt=""/>
            <div>
              <h3 className="mt-3 mb-2">Integrative therapy</h3>
              <p>Acknowledges that you are a unique individual with your own thoughts and experiences. Taking the most effective techniques from different types of therapy, we will work together to find an approach that works best for you.</p>
            </div>
          </Col>
          <Col xs={{span:12}} sm={{span:10}} lg={{span:8}} xl={{span:3}} className="my-5 my-xl-0 mx-xl-5">
              <Image height="50" width="50" src={faDoorOpen} size="2x" className="mx-auto" alt=""/>
            <div>
              <h3 className="mt-3 mb-2">Accepting of all</h3>
              <p>No matter who you are or where you come from I am accepting of ALL people and will listen to you in a caring and non-judgmental way. This is a place for you to feel heard and understood. Whatever is troubling you, we can work through it together.</p>
            </div>
          </Col>
          <Col xs={{span:12}} sm={{span:10}} lg={{span:8}} xl={{span:3}}>
            <Image height="50" width="50" src={faBadgeCheck} size="2x" className="mx-auto" alt=""/>
            <div>
              <h3 className="mt-3 mb-2">On and Offline</h3>
              <p>Offering face-to-face sessions as well as online counselling to individuals. Based in Doncaster, sessions take place in my warm and private counselling room. It is my goal to create a comfortable and safe counselling space for you whether that be in person or online.</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid="xl">
        <Row className="my-3">
          <Col xs={12} lg={{span:10, offset:1}} className="mt-5 text-center">
            <h2>How counselling can help</h2>
          </Col>
          <Col xs={12} sm={{span:5, offset:1}} lg={{span:6, offset:0}} className="px-5 px-sm-4 d-flex flex-column" >
            <Image src={lost} fluid alt=""/>
            <Button href="/should-I-get-counselling/" block className="mt-2 d-none d-sm-block d-md-none" variant="outline-dark">
              Find out more
            </Button>
          </Col>
          <Col xs={12} sm={{span:5, offset:1}} className="p-3 pl-sm-0 pr-lg-5">
            <p>Counselling, also known as talking therapy, is a way to help you deal with difficulties in your life or emotional problems.</p>
            <p>The aim is to always work with you towards achieving your goals, one step at a time and at a pace that suits you. </p>
            <p>If you are considering getting help, counselling is a great tool! Did you know many people find counselling as, or more, effective than medication?</p>
            <p>Taking that next step can feel daunting. Remember you ARE strong enough and have it within you to take that next step.</p>
            <p>I've put together a list of things to look out for if you're still deciding whether counselling is right for you</p>
            <Button href="/should-I-get-counselling/" block className="p-2 d-sm-none d-md-block" variant="outline-dark">
              Should I get counselling?
            </Button>
          </Col>
        </Row>
      </Container>
      <Container fluid="xl" className="my-5">
        <Row className="py-3 bg-pattern rounded-lg">
          <Col xs={{span:10,offset:1}}>
            <blockquote class="blockquote text-right my-lg-3">
              <p class="mb-0">Sometimes it's easier to talk to a stranger than to relatives or friends.</p>
                <footer class="blockquote-footer">NHS<cite>"Benefits of talking therapy"</cite>
                </footer>
            </blockquote>
          </Col>
        </Row>
      </Container>
      <HelpList />
      <Container fluid="xl">
        <Row className="mt-3 mb-sm-3 mb-lg-0">
          <Col xs={12} lg={{span:10, offset:1}} className="mt-5 text-center">
            <h2>Meet Alex</h2>
          </Col>
          <Col xs={12} sm={{span:5, offset:1}} className="p-3 pr-lg-5">
            <p>Hi, I'm Alex. I'll be your counsellor.</p>
            <p>I'm a professionally qualified integrative therapist. I'm a member of the BACP (British Association for Counselling and Psychotherapy).</p>
            <p>I know that sometimes reaching out can be difficult, so I want to introduce myself to you, and give you an idea of what to expect in a therapy session.</p>
            <Button href="/about#meet-alex/" block className="mx-auto p-2 d-sm-none d-md-block" variant="outline-dark">
              About YourTherapy
            </Button>
          </Col>
          <Col xs={12} sm={{span:4, offset:1}}  className="px-5 px-sm-0 d-flex flex-column">
            <Image src={alex} fluid alt="Portrait of Alexandra Oliver, counsellor at YourTherapy Doncaster"/>
            <Button href="/about#meet-alex/" block className="p-2 mx-auto mt-2 d-none d-sm-block d-md-none" variant="outline-dark">
              About YourTherapy
            </Button>
          </Col>
        </Row>
      </Container>
      <ContactCTA />
      <Testimonials />
    </Layout>
  )
}
